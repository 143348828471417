import MainLayout from "layout/MainLayout";
import { AuthenticationGuard } from "utils/route-guard/AuthenticationGuard";
import { RouteObject } from "react-router";
import DefaultDashboard from "pages/dashboards/default";
import SessionsPage from "pages/sessions";
import ChargePointsPage from "pages/charge-points";
import SitesPage from "pages/sites";
import SamplePage from "pages/extra-pages/sample-page";
import ChargePointDetailPage from "pages/charge-points/view";
import PowerManagementPage from "pages/power/power-management";

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes: RouteObject = {
  element: <AuthenticationGuard component={MainLayout} />,
  children: [
    {
      index: true,
      element: <DefaultDashboard />,
    },
    {
      path: "sites",
      element: <SitesPage />,
    },
    {
      path: "sessions",
      element: <SessionsPage />,
    },
    {
      path: "charge-points",
      children: [
        {
          index: true,
          element: <ChargePointsPage />,
        },
        {
          path: ":id",
          id: "charge-point-details",
          element: <ChargePointDetailPage />,
        },
      ],
    },
    {
      path: "power",
      element: <PowerManagementPage />,
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
  ],
};

export default MainRoutes;
