import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// third-party
import { Provider as ReduxProvider } from "react-redux";

// scroll bar
import "simplebar/src/simplebar.css";

// apex-chart
import "assets/third-party/apex-chart.css";
import "assets/third-party/react-table.css";

// skeleton
import "react-loading-skeleton/dist/skeleton.css";

// project import
import App from "./App";
import { store } from "store";
import { ConfigProvider } from "contexts/ConfigContext";
import reportWebVitals from "./reportWebVitals";

import { Auth0Context, Auth0Provider } from "@auth0/auth0-react";
// import useAuth from "hooks/useAuth";
import { sec } from "utils/security";

const container = document.getElementById("root");
const root = createRoot(container!);

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!!}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!!}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL!!}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE!!}
    scope="admin read:charge_points manage:charge_points"
  >
    <Auth0Context.Consumer>
      {({ getAccessTokenSilently }) => {
        // expose the getAccessTokenSilently to non-react components, ie. RTK slices
        sec.setAccessTokenSilently(getAccessTokenSilently);
        return (
          <>
            <ReduxProvider store={store}>
              <ConfigProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ConfigProvider>
            </ReduxProvider>
          </>
        );
      }}
    </Auth0Context.Consumer>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
