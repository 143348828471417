import { Chart } from "react-chartjs-2";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler,
} from 'chart.js';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler,
);

export interface ChartData {
  billperiod: number;
  secondarydemand: number;
  startdate: number;
  startyear: number;
  startmonth: number;
  startday: number;
  dailyusage: number;
  dailymaxtmp: number;
  dailymintmp: number;
}

const PowerUsageCharge = (props: { chartData: ChartData[] }) => {
  // const theme = useTheme();
  // const { mode } = useConfig();

  // const { primary, secondary } = theme.palette.text;
  // const line = theme.palette.divider;

  const labels = props.chartData.map((d) => `${d.startmonth}/${d.startday}`)
  const data = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Usage',
        data: props.chartData.map((d) => d.dailyusage),
        backgroundColor: '#2aa1af',
        borderColor: '#2aa1af',
        borderWidth: 2,
        yAxisID: 'yUsage',
      },
      // {
      //   type: 'line' as const,
      //   label: 'Peek Usage',
      //   data: props.chartData.map((d) => d.secondarydemand),
      //   backgroundColor: 'black',
      //   borderColor: 'black',
      //   borderWidth: 2,
      //   yAxisID: 'yUsage',
      // },
      {
        type: 'line' as const,
        label: 'Min Temp',
        data: props.chartData.map((d) => d.dailymintmp),
        borderColor: 'rgb(225, 240, 239)',
        backgroundColor: 'rgb(225, 240, 239)',
        borderWidth: 2,
        fill: '+1',
        yAxisID: 'yTemp',
      },
      {
        type: 'line' as const,
        label: 'Max Temp',
        data: props.chartData.map((d) => d.dailymaxtmp),
        backgroundColor: 'rgb(225, 240, 239)',
        borderColor: 'rgb(225, 240, 239)',
        yAxisID: 'yTemp',
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    elements: {
      point: {
        pointStyle: false,
      },
    },
    title: {
      display: true,
      text: 'Temperature Sensitivity Analysis',
      color: 'black',
      position: 'top',
    },
    scales: {
      yTemp: {
        type: 'linear',
        display: true,
        suggestedMin: 0,
        suggestedMax: 110,
        title: {
          text: "Daily temperature range (°F)",
          display: true,
        },
        position: 'right',
        grid: {
          drawOnChartArea: false,
        }
      },
      yUsage: {
        type: 'linear',
        display: true,
        suggestedMin: 0,
        suggestedMax: 10000,
        title: {
          display: true,
          text: "Usage (kWh)",
        },
        position: 'left'
      }
    }
  };


  // @ts-ignore idk why its complaining about the scales options
  return <Chart data={data} options={options} type="line" />;
};

export default PowerUsageCharge;