import { useRoutes } from "react-router-dom";

// project import
// import LoginRoutes from './LoginRoutes';
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export const routes = [MainRoutes];

export default function ThemeRoutes() {
  return useRoutes(routes);
}
