// third-party
import { FormattedMessage } from "react-intl";

// assets
import { DashboardOutlined } from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  DashboardOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard: NavItemType = {
  id: "group-dashboard",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="overview" />,
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
