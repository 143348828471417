// material-ui
import Grid from "@mui/material/Grid";
// project import
import MainCard from "components/MainCard";
import {
  TableContainer,
  Table,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TableHead,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import PowerUsageCharge, { ChartData } from "components/charts/PowerUsageCharge";
import { FormattedMessage } from "react-intl";

import data from "../../data/mp/coned/2022-2023-processed.json"
import rawChartData from "../../data/mp/coned/2022-2023-chart.json"
import { useEffect, useState } from "react";

// ==============================|| SAMPLE PAGE ||============================== //

const PowerManagementPage = () => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: 2,
  });



  const firstBillingPeriod = data[0].billingPeriod;
  const [billingPeriod, setBillingPeriod] = useState(firstBillingPeriod);

  const meters = data[0].meters.map((meter) => meter.meterId);
  const [selectedMeterId, setSelectedMeterId] = useState(meters[0]);
  const [selectedMeter, setSelectedMeter] = useState(data[0].meters[0]);

  const [filterGravityUsage, setFilterGravityUsage] = useState(false);
  const [filteredStyles, setFilteredStyles] = useState({});

  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    if (filterGravityUsage) {
      setFilteredStyles({
        color: "gray",
        textDecoration: "line-through",
      });
    } else {
      setFilteredStyles({})
    }
  }, [filterGravityUsage]);


  useEffect(() => {
    const d = data.find((d) => d.billingPeriod === billingPeriod);
    setSelectedMeterId(d!!.meters[0].meterId)
    setSelectedMeter(d!!.meters[0])
  }, [billingPeriod]);

  useEffect(() => {
    const d = data.find((d) => d.billingPeriod === billingPeriod);

    let found: any = null;
    for (let i = 0; i < d!!.meters.length; i++) {
      if (d!!.meters[i].meterId === selectedMeterId) {
        found = d!!.meters[i];
        break;
      }
    }

    setSelectedMeter(found!!);

    const billingPeriods = data.map((d) => d.billingPeriod);
    const currentPeriodIndex = billingPeriods.indexOf(billingPeriod);
    const billingRange = billingPeriods.slice(Math.max(0, currentPeriodIndex - 3), currentPeriodIndex);
    const filteredChartData = rawChartData.filter((d) => billingRange.includes(d.billperiod));
    setChartData(filteredChartData.map((d) => {
      const cd: ChartData = {
        billperiod: d.billperiod,
        startdate: d.startdate as number,
        startyear: d.startyear as number,
        startmonth: d.startmonth as number,
        startday: d.startday as number,
        dailymaxtmp: d.dailymaxtmp as number,
        dailymintmp: d.dailymintmp as number,
        secondarydemand: 0,
        dailyusage: 0
      };

      if (found!!.meterId === "7008") {
        cd.dailyusage = d.dailyusage_7008;
        cd.secondarydemand = d.secondarydemand_7008 as number;
      } else {
        cd.dailyusage = d.dailyusage_8006;
        cd.secondarydemand = d.secondarydemand_8006 as number;
      }

      return cd as ChartData;
    }));
  }, [selectedMeterId, billingPeriod]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filterGravityUsage}
                      onChange={(e) => setFilterGravityUsage(e.target.checked)} />
                  }
                  label={
                    <FormattedMessage id="remove-gravity" />
                  } />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              select
              value={billingPeriod}
              onChange={(e) => setBillingPeriod(parseInt(e.target.value))}
            >
              {data.map((option) => (
                <MenuItem key={option.billingPeriod} value={option.billingPeriod}>
                  {option.billingPeriod}
                </MenuItem>
              ))}
            </TextField>
            <span>&nbsp;&nbsp;</span>
            <TextField
              size="small"
              select
              value={selectedMeterId}
              onChange={(e) => setSelectedMeterId(e.target.value)}
            >
              {meters.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainCard>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <h3>Temperature Sensitivity Analysis</h3>
              <div style={{ height: '250px', width: '100%' }}>
                <PowerUsageCharge chartData={chartData} />
              </div>
            </Grid>
            {/* row 1 */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TableContainer sx={{ paddingBottom: 2 }}>
                <Table size="small" sx={{ border: 1 }}>
                  <TableBody>
                    <TableRow hover={false} sx={{ bgcolor: '#2aa1af' }} key="head1">
                      <TableCell sx={{ color: 'white' }} colSpan={2}>
                        <span style={{ fontSize: '1.6em', fontWeight: 'bold' }}>Your electricity breakdown </span>
                        <span style={{ fontSize: '1.2em', fontStyle: 'italic' }}>Rate: El12 Multiple Dwelling Heating</span></TableCell>
                    </TableRow>
                    <TableRow hover={false} key="head2">
                      <TableCell sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>Electric Meter Detail {selectedMeter.meterId} - billing period {billingPeriod} </TableCell>
                      <TableCell align="right" sx={{ fontSize: '1.2em', fontWeight: 'bold' }}>
                        {filterGravityUsage === false &&
                          <span>{currencyFormatter.format(1.045 * (
                            ((selectedMeter.deliverycustomercharge as number)) +
                            (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                            (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                            (((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                            (((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                            ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                            ((selectedMeter.deliverygrtandtaxcharge as number)) +
                            (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                            (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                            (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                            (selectedMeter.supplymerchantfunctioncharge as number) +
                            (selectedMeter.supplygrtandtaxsurcharges as number)
                          ))}</span>
                        }
                        {filterGravityUsage && (<>
                          <span style={filteredStyles}>
                            {currencyFormatter.format(1.045 * (
                              ((selectedMeter.deliverycustomercharge as number)) +
                              (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                              (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                              (((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                              (((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                              ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                              ((selectedMeter.deliverygrtandtaxcharge as number)) +
                              (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                              (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                              (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                              (selectedMeter.supplymerchantfunctioncharge as number) +
                              (selectedMeter.supplygrtandtaxsurcharges as number)
                            ))}
                          </span>&nbsp;
                          <span>{currencyFormatter.format(1.045 * (
                            ((selectedMeter.deliverycustomercharge as number) * .66667) +
                            (((selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                            (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                            (((selectedMeter.primarydemand_wo as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                            (((selectedMeter.secondarydemand_wo as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                            ((((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) - (selectedMeter.submeters[2].peakusagekwh + selectedMeter.submeters[2].offpeakusagekwh)) as number) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                            ((selectedMeter.deliverygrtandtaxcharge as number) * .66667) +
                            (((selectedMeter.onpeakenergy1020wd - (selectedMeter.submeters[2].peakusagekwh)) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                            (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                            (((selectedMeter.supplycapacity * .66667) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                            ((selectedMeter.supplymerchantfunctioncharge as number) * .66667) +
                            ((selectedMeter.supplygrtandtaxsurcharges as number) * .66667)
                          ))}
                          </span>
                        </>)}



                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer sx={{ paddingTop: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={false} sx={{ fontWeight: 'bold' }} key="head3">
                      <TableCell>Type</TableCell>
                      <TableCell>
                        On peak energy<br />
                        (Mon-Fri 8am-10pm)
                      </TableCell>
                      <TableCell>
                        Off peak energy<br />
                        (all other hours)
                      </TableCell>
                      <TableCell>
                        G&T demand<br />
                        (Mon-Fri 8am-6pm)
                      </TableCell>
                      <TableCell>
                        Primary demand<br />
                        (Mon-Fri 8am-10pm)
                      </TableCell>
                      <TableCell>
                        Secondary demand<br />
                        (all hours, all days)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow hover={false} id="data" key="data1">

                      <TableCell>Low Tension</TableCell>
                      <TableCell>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.onpeakenergy1020wd)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;{numberFormatter.format(selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh)}
                          </span>
                        } kWh
                      </TableCell>
                      <TableCell>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.offpeakenergy)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;{numberFormatter.format(selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh)}
                          </span>
                        } kWh
                      </TableCell>
                      <TableCell>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.gandtdemand_bill as number)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;{numberFormatter.format(selectedMeter.gandtdemand_wo as number)}
                          </span>
                        } kW
                      </TableCell>
                      <TableCell>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.primarydemand_bill)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;{numberFormatter.format(selectedMeter.primarydemand_wo as number)}
                          </span>
                        } kW
                      </TableCell>
                      <TableCell>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.secondarydemand_bill as number)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;{numberFormatter.format(selectedMeter.secondarydemand_wo as number)}
                          </span>
                        } kW
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow hover={false} key="total1">
                      <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>TOTAL ELECTRICITY USE</TableCell>
                      <TableCell align="right" colSpan={5} sx={{ fontWeight: 'bold', color: 'black' }}>{numberFormatter.format(selectedMeter.totalelectricityuse)} kWh</TableCell>
                    </TableRow>
                    <TableRow hover={false} key="total2">
                      <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>TOTAL DEMAND USE</TableCell>
                      <TableCell align="right" colSpan={5} sx={{ fontWeight: 'bold', color: 'black' }}>{numberFormatter.format(selectedMeter.totaldemanduse)} kW</TableCell>
                    </TableRow>
                    <TableRow hover={false} key="total3">
                      <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>BILLABLE kVAR</TableCell>
                      <TableCell align="right" colSpan={5} sx={{ fontWeight: 'bold', color: 'black' }}>{numberFormatter.format(selectedMeter.totalbillablekvar)}</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <TableContainer sx={{ paddingTop: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={false} sx={{ fontWeight: 'bold' }} key="head4">
                      <TableCell colSpan={2}>Service/<br />Tension</TableCell>
                      <TableCell>Meter #</TableCell>
                      <TableCell>New Reading</TableCell>
                      <TableCell>Reading Type</TableCell>
                      <TableCell>Prior Reading</TableCell>
                      <TableCell>Reading Type</TableCell>
                      <TableCell>Reading Diff</TableCell>
                      <TableCell>Multiplier</TableCell>
                      <TableCell align="right">Total Usage <br />kWh/kW</TableCell>
                      <TableCell align="right">On peak</TableCell>
                      <TableCell align="right">Off peak</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedMeter.submeters.map((submeter, i) => {
                      let styles = {};
                      if (i === selectedMeter.submeters.length - 1) {
                        styles = filteredStyles
                      }
                      return (<TableRow hover={false} key={`row1_${i}`} >
                        <TableCell sx={styles}>E</TableCell>
                        <TableCell sx={styles}>L</TableCell>
                        <TableCell sx={styles}>{submeter.subMeterId}</TableCell>
                        <TableCell sx={styles}>{submeter.newreadingactual}</TableCell>
                        <TableCell sx={styles}>Actual</TableCell>
                        <TableCell sx={styles}>{submeter.priorreadingactual}</TableCell>
                        <TableCell sx={styles}>Actual</TableCell>
                        <TableCell sx={styles}>{numberFormatter.format(submeter.newreadingactual - submeter.priorreadingactual)}</TableCell>
                        <TableCell sx={styles}>{submeter.multiplier}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.totalusagekwh)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.peakusagekwh)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.offpeakusagekwh)}</TableCell>
                      </TableRow>)
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow hover={false} key="head5">
                      <TableCell colSpan={9} sx={{ fontWeight: 'bold', color: 'black' }}>TOTAL</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.totalelectricityuse)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(selectedMeter.totalelectricityuse - selectedMeter.submeters[2].totalusagekwh)}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(
                            selectedMeter.submeters[0].peakusagekwh +
                            selectedMeter.submeters[1].peakusagekwh +
                            selectedMeter.submeters[2].peakusagekwh
                          )}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(
                              selectedMeter.submeters[0].peakusagekwh +
                              selectedMeter.submeters[1].peakusagekwh
                            )}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(
                            selectedMeter.submeters[0].offpeakusagekwh +
                            selectedMeter.submeters[1].offpeakusagekwh +
                            selectedMeter.submeters[2].offpeakusagekwh
                          )}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(
                              selectedMeter.submeters[0].offpeakusagekwh +
                              selectedMeter.submeters[1].offpeakusagekwh
                            )}
                          </span>
                        }

                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <TableContainer sx={{ paddingTop: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={false} sx={{ fontWeight: 'bold' }} key="head6">
                      <TableCell colSpan={2}>Service/<br />Tension</TableCell>
                      <TableCell>Meter #</TableCell>
                      <TableCell>New Reading</TableCell>
                      <TableCell>Reading Type</TableCell>
                      <TableCell>Prior Reading</TableCell>
                      <TableCell>Reading Type</TableCell>
                      <TableCell>Reading Diff</TableCell>
                      <TableCell>Multiplier</TableCell>
                      <TableCell align="right">Total Usage <br />kWh/kW</TableCell>
                      <TableCell align="right">G&T Demand</TableCell>
                      <TableCell align="right">Primary Demand</TableCell>
                      <TableCell align="right">Secondary Demand</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedMeter.submeters.map((submeter, i) => {
                      let styles = {};
                      if (i === selectedMeter.submeters.length - 1) {
                        styles = filteredStyles
                      }
                      return (<TableRow hover={false} key={`row2_${i}`} >
                        <TableCell sx={styles}>D</TableCell>
                        <TableCell sx={styles}>L</TableCell>
                        <TableCell sx={styles}>{submeter.subMeterId}</TableCell>
                        <TableCell sx={styles}>{numberFormatter.format(submeter.dnewreadingactual)}</TableCell>
                        <TableCell sx={styles}>Actual</TableCell>
                        <TableCell sx={styles}>{submeter.dpriorreadingactual}</TableCell>
                        <TableCell sx={styles}>Actual</TableCell>
                        <TableCell sx={styles}>{numberFormatter.format(submeter.dnewreadingactual - submeter.dpriorreadingactual)}</TableCell>
                        <TableCell sx={styles}>{submeter.dmultiplier}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.dtotalusagekw)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.gandtdemand as number)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.primarydemand as number)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.secondarydemand as number)}</TableCell>
                      </TableRow>);
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow hover={false} key="head7">
                      <TableCell colSpan={9} sx={{ fontWeight: 'bold', color: 'black' }}>TOTAL</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(
                            selectedMeter.submeters[0].dtotalusagekw +
                            selectedMeter.submeters[1].dtotalusagekw +
                            selectedMeter.submeters[2].dtotalusagekw
                          )}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(
                              selectedMeter.submeters[0].dtotalusagekw +
                              selectedMeter.submeters[1].dtotalusagekw
                            )}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.gandtdemand_calc as number)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(selectedMeter.gandtdemand_wo as number)}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.primarydemand_calc as number)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(selectedMeter.primarydemand_wo as number)}
                          </span>
                        }
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>
                        <span style={filteredStyles}>
                          {numberFormatter.format(selectedMeter.secondarydemand_calc as number)}
                        </span>
                        {filterGravityUsage &&
                          <span>
                            &nbsp;
                            {numberFormatter.format(selectedMeter.secondarydemand_wo as number)}
                          </span>
                        }
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>

              <TableContainer sx={{ paddingTop: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow hover={false} sx={{ fontWeight: 'bold' }} key="head8">
                      <TableCell>Service</TableCell>
                      <TableCell>Tension</TableCell>
                      <TableCell>Meter #</TableCell>
                      <TableCell>Reading Type</TableCell>
                      <TableCell align="right">Power Factor</TableCell>
                      <TableCell align="right">Actual kVar</TableCell>
                      <TableCell align="right">Allowable kVar</TableCell>
                      <TableCell align="right">Billable kVar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedMeter.submeters.map((submeter, i) => {
                      let styles = {};
                      if (i === selectedMeter.submeters.length - 1) {
                        styles = filteredStyles
                      }
                      return (<TableRow hover={false} key={`row3_${i}`}>
                        <TableCell sx={styles}>R</TableCell>
                        <TableCell sx={styles}>L</TableCell>
                        <TableCell sx={styles}>{submeter.subMeterId}</TableCell>
                        <TableCell sx={styles}>Actual</TableCell>
                        <TableCell sx={styles} align="right">{submeter.rpowerfactor}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.actualkvar)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.allowablekvar)}</TableCell>
                        <TableCell sx={styles} align="right">{numberFormatter.format(submeter.billablekvar)}</TableCell>
                      </TableRow>);
                    })}
                  </TableBody>
                  <TableFooter>
                    <TableRow hover={false} key="head9">
                      <TableCell colSpan={7} sx={{ fontWeight: 'bold', color: 'black' }}>TOTAL</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold', color: 'black' }}>0.00</TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>

            {/* row 2 */}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <h2>Your Supply Charges</h2>
              <Table size="small">
                <TableBody>
                  <TableRow key="line1">
                    <TableCell>
                      On peak <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.onpeakenergy1020wd)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format(selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh)}
                        </span>
                      } kWh @ {numberFormatter.format(selectedMeter.supplypeakpricekwh as number)}¢/kWh</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.onpeakenergy1020wd as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.onpeakenergy1020wd - (selectedMeter.submeters[2].peakusagekwh)) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line2">
                    <TableCell>
                      Off peak <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.offpeakenergy)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format(selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh)}
                        </span>
                      } kWh @ {numberFormatter.format(selectedMeter.supplyoffpeakpricekwh as number)}¢/kWh</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.offpeakenergy as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line2.5">
                    <TableCell>
                      Capacity charge <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.supplycapacity)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format(selectedMeter.supplycapacity * .66667)}
                        </span>
                      } kW @ {numberFormatter.format(selectedMeter.supplycapacitychargekw as number)}¢/kW</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.supplycapacity as number) * ((selectedMeter.supplycapacitychargekw as number))
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.supplycapacity * .66667) as number) * ((selectedMeter.supplycapacitychargekw as number))
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line3">
                    <TableCell>Merchant function charge</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(selectedMeter.supplymerchantfunctioncharge as number)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format((selectedMeter.supplymerchantfunctioncharge as number) * .66667)}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line4">
                    <TableCell>GRT & other tax surcharges</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(selectedMeter.supplygrtandtaxsurcharges as number)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format((selectedMeter.supplygrtandtaxsurcharges as number) * .66667)}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: '#e1f0ef', fontWeight: 'bold' }} key="line5">
                    <TableCell sx={{ fontWeight: 'bold' }}>TOTAL ELECTRICITY SUPPLY CHARGES</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {filterGravityUsage === false &&
                        <><span>{currencyFormatter.format(
                          (((selectedMeter.onpeakenergy1020wd as number) * ((selectedMeter.supplypeakpricekwh as number) / 100))) +
                          (((selectedMeter.offpeakenergy) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100))) +
                          (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          ((selectedMeter.supplymerchantfunctioncharge as number)) +
                          ((selectedMeter.supplygrtandtaxsurcharges as number))
                        )}</span></>
                      }
                      {filterGravityUsage && (<>
                        <span style={filteredStyles}>
                          {currencyFormatter.format(
                            (((selectedMeter.onpeakenergy1020wd as number) * ((selectedMeter.supplypeakpricekwh as number) / 100))) +
                            (((selectedMeter.offpeakenergy) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100))) +
                            (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                            ((selectedMeter.supplymerchantfunctioncharge as number)) +
                            ((selectedMeter.supplygrtandtaxsurcharges as number))
                          )}
                        </span>&nbsp;
                        <span>{currencyFormatter.format(
                          (((selectedMeter.onpeakenergy1020wd - (selectedMeter.submeters[2].peakusagekwh)) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                          (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                          (((selectedMeter.supplycapacity * .66667) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          ((selectedMeter.supplymerchantfunctioncharge as number) * .66667) +
                          ((selectedMeter.supplygrtandtaxsurcharges as number) * .66667)
                        )}
                        </span>
                      </>)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <h2>Your Delivery Charges</h2>
              <Table size="small">
                <TableBody>
                  <TableRow key="line1">
                    <TableCell>Customer Charge</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(selectedMeter.deliverycustomercharge as number)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format((selectedMeter.deliverycustomercharge as number) * .66667)}
                        </span>
                      }</TableCell>
                  </TableRow>
                  <TableRow key="line2">
                    <TableCell>
                      On peak <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.onpeakenergy1020wd)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format(selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh)}
                        </span>
                      } kWh @ {numberFormatter.format(selectedMeter.deliveryonpeakkwhprice as number)}¢/kWh</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.onpeakenergy1020wd as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line3">
                    <TableCell>
                      Off peak <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.offpeakenergy)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format(selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh)}
                        </span>
                      } kWh @ {numberFormatter.format(selectedMeter.deliveryoffpeakkwhprice as number)}¢/kWh</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.offpeakenergy as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line4">
                    <TableCell>
                      G & T demand <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.gandtdemand_bill)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format((selectedMeter.gandtdemand_wo as number))}
                        </span>
                      } kW @ {currencyFormatter.format(selectedMeter.deliverygandtprice as number)}/kW</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.gandtdemand_bill as number) * ((selectedMeter.deliverygandtprice as number))
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.gandtdemand_wo as number)) * ((selectedMeter.deliverygandtprice as number))
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line5">
                    <TableCell>
                      Primary demand <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.primarydemand_bill)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format((selectedMeter.primarydemand_wo as number))}
                        </span>
                      } kW @ {currencyFormatter.format(selectedMeter.deliveryprimaryprice as number)}/kW</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.primarydemand_bill as number) * ((selectedMeter.deliveryprimaryprice as number))
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.primarydemand_wo as number)) * ((selectedMeter.deliveryprimaryprice as number))
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line6">
                    <TableCell>
                      Secondary demand <span style={filteredStyles}>
                        {numberFormatter.format(selectedMeter.secondarydemand_bill as number)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;{numberFormatter.format((selectedMeter.secondarydemand_wo as number))}
                        </span>
                      } kW @ {currencyFormatter.format(selectedMeter.deliverysecondaryprice as number)}/kW</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          (selectedMeter.secondarydemand_bill as number) * ((selectedMeter.deliverysecondaryprice as number))
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            ((selectedMeter.secondarydemand_wo as number)) * ((selectedMeter.deliverysecondaryprice as number))
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line7">
                    <TableCell>Reactive - Power demand 0.0 kVar @$0.0000 / kW</TableCell>
                    <TableCell align="right">$0.00</TableCell>
                  </TableRow>
                  <TableRow key="line8">
                    <TableCell>System Benefit Charge @{numberFormatter.format(selectedMeter.deliverysystembenefitchargekwh as number)}¢/kWh</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(
                          ((selectedMeter.onpeakenergy1020wd as number) + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)
                        )}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format(
                            (((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) - (selectedMeter.submeters[2].peakusagekwh + selectedMeter.submeters[2].offpeakusagekwh)) as number) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)
                          )}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow key="line9">
                    <TableCell>Billing and payment processing charge</TableCell>
                    <TableCell align="right">{currencyFormatter.format(selectedMeter.deliverybillingandpaymentprocess)}</TableCell>
                  </TableRow>
                  <TableRow key="line10">
                    <TableCell>GRT & other tax surcharges</TableCell>
                    <TableCell align="right">
                      <span style={filteredStyles}>
                        {currencyFormatter.format(selectedMeter.deliverygrtandtaxcharge as number)}
                      </span>
                      {filterGravityUsage &&
                        <span>
                          &nbsp;
                          {currencyFormatter.format((selectedMeter.deliverygrtandtaxcharge as number) * .66667)}
                        </span>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: '#e1f0ef', fontWeight: 'bold' }} key="line11">
                    <TableCell sx={{ fontWeight: 'bold' }}>TOTAL ELECTRICITY DELIVERY CHARGES</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {filterGravityUsage === false &&
                        <><span>{currencyFormatter.format(
                          ((selectedMeter.deliverycustomercharge as number)) +
                          (selectedMeter.deliverybillingandpaymentprocess as number) +
                          ((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                          ((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                          ((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                          ((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                          ((((selectedMeter.onpeakenergy1020wd as number) + selectedMeter.offpeakenergy)) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          (selectedMeter.deliverygrtandtaxcharge as number)
                        )}</span></>
                      }
                      {filterGravityUsage && (<>
                        <span style={filteredStyles}>
                          {currencyFormatter.format(
                            ((selectedMeter.deliverycustomercharge as number)) +
                            (selectedMeter.deliverybillingandpaymentprocess as number) +
                            ((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                            ((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                            ((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                            ((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                            ((((selectedMeter.onpeakenergy1020wd as number) + selectedMeter.offpeakenergy)) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                            (selectedMeter.deliverygrtandtaxcharge as number)
                          )}
                        </span>&nbsp;
                        <span>{currencyFormatter.format(
                          ((selectedMeter.deliverycustomercharge as number) * .66667) +
                          (selectedMeter.deliverybillingandpaymentprocess as number) +
                          ((selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                          ((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                          ((selectedMeter.primarydemand_wo as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                          ((selectedMeter.secondarydemand_wo as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                          ((((selectedMeter.onpeakenergy1020wd as number) + selectedMeter.offpeakenergy)) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          (selectedMeter.deliverygrtandtaxcharge as number) * .66667
                        )}
                        </span>
                      </>)}
                    </TableCell>
                  </TableRow>
                  <TableRow key="line12">
                    <TableCell>Sales tax @4.5%</TableCell>
                    <TableCell align="right">
                      {filterGravityUsage === false &&
                        <><span>{currencyFormatter.format(.045 * (
                          ((selectedMeter.deliverycustomercharge as number)) +
                          ((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                          ((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                          ((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                          ((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                          ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          (selectedMeter.deliverygrtandtaxcharge as number) +
                          (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                          (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                          (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          (selectedMeter.supplymerchantfunctioncharge as number) +
                          (selectedMeter.supplygrtandtaxsurcharges as number)
                        ))}</span></>
                      }
                      {filterGravityUsage && (<>
                        <span style={filteredStyles}>
                          {currencyFormatter.format(.045 * (
                            ((selectedMeter.deliverycustomercharge as number)) +
                            ((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                            ((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                            ((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                            ((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                            ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                            (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                            (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                            (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                            (selectedMeter.supplymerchantfunctioncharge as number) +
                            (selectedMeter.supplygrtandtaxsurcharges as number))
                          )}
                        </span>&nbsp;
                        <span>{currencyFormatter.format(.045 * (
                          ((selectedMeter.deliverycustomercharge as number) * .66667) +
                          ((selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100) +
                          ((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100) +
                          ((selectedMeter.primarydemand_wo as number)) * ((selectedMeter.deliveryprimaryprice as number)) +
                          ((selectedMeter.secondarydemand_wo as number)) * ((selectedMeter.deliverysecondaryprice as number)) +
                          ((((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) - (selectedMeter.submeters[2].peakusagekwh + selectedMeter.submeters[2].offpeakusagekwh)) as number) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          (selectedMeter.deliverygrtandtaxcharge as number) * .66667 +
                          (((selectedMeter.onpeakenergy1020wd - (selectedMeter.submeters[2].peakusagekwh)) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                          (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                          (((selectedMeter.supplycapacity * .66667) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          ((selectedMeter.supplymerchantfunctioncharge as number) * .66667) +
                          ((selectedMeter.supplygrtandtaxsurcharges as number) * .66667)
                        )
                        )}
                        </span>
                      </>)}

                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ bgcolor: '#e1f0ef', fontWeight: 'bold' }} key="line13">
                    <TableCell sx={{ fontWeight: 'bold' }}>YOUR ELECTRICITY TOTAL</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                      {filterGravityUsage === false &&
                        <span>{currencyFormatter.format(1.045 * (
                          ((selectedMeter.deliverycustomercharge as number)) +
                          (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                          (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                          (((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                          (((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                          ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          ((selectedMeter.deliverygrtandtaxcharge as number)) +
                          (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                          (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                          (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          (selectedMeter.supplymerchantfunctioncharge as number) +
                          (selectedMeter.supplygrtandtaxsurcharges as number)
                        ))}</span>
                      }
                      {filterGravityUsage && (<>
                        <span style={filteredStyles}>
                          {currencyFormatter.format(1.045 * (
                            ((selectedMeter.deliverycustomercharge as number)) +
                            (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                            (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                            (((selectedMeter.primarydemand_bill as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                            (((selectedMeter.secondarydemand_bill as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                            ((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                            ((selectedMeter.deliverygrtandtaxcharge as number)) +
                            (((selectedMeter.onpeakenergy1020wd) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                            (((selectedMeter.offpeakenergy) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                            (((selectedMeter.supplycapacity) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                            (selectedMeter.supplymerchantfunctioncharge as number) +
                            (selectedMeter.supplygrtandtaxsurcharges as number)
                          ))}
                        </span>&nbsp;
                        <span>{currencyFormatter.format(1.045 * (
                          ((selectedMeter.deliverycustomercharge as number) * .66667) +
                          (((selectedMeter.onpeakenergy1020wd - selectedMeter.submeters[2].peakusagekwh) as number) * ((selectedMeter.deliveryonpeakkwhprice as number) / 100)) +
                          (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.deliveryoffpeakkwhprice as number) / 100)) +
                          (((selectedMeter.primarydemand_wo as number)) * ((selectedMeter.deliveryprimaryprice as number))) +
                          (((selectedMeter.secondarydemand_wo as number)) * ((selectedMeter.deliverysecondaryprice as number))) +
                          ((((selectedMeter.onpeakenergy1020wd + selectedMeter.offpeakenergy) - (selectedMeter.submeters[2].peakusagekwh + selectedMeter.submeters[2].offpeakusagekwh)) as number) * ((selectedMeter.deliverysystembenefitchargekwh as number) / 100)) +
                          ((selectedMeter.deliverygrtandtaxcharge as number) * .66667) +
                          (((selectedMeter.onpeakenergy1020wd - (selectedMeter.submeters[2].peakusagekwh)) as number) * ((selectedMeter.supplypeakpricekwh as number) / 100)) +
                          (((selectedMeter.offpeakenergy - selectedMeter.submeters[2].offpeakusagekwh) as number) * ((selectedMeter.supplyoffpeakpricekwh as number) / 100)) +
                          (((selectedMeter.supplycapacity * .66667) as number) * ((selectedMeter.supplycapacitychargekw as number))) +
                          ((selectedMeter.supplymerchantfunctioncharge as number) * .66667) +
                          ((selectedMeter.supplygrtandtaxsurcharges as number) * .66667)
                        ))}
                        </span>
                      </>)}

                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            {/* end row 2 */}
          </Grid>
        </MainCard>
      </Grid >

    </Grid >

  );
};

export default PowerManagementPage;
