import { useState, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// project import
import useConfig from "hooks/useConfig";

// third-party
import ReactApexChart, { Props as ChartProps } from "react-apexcharts";
import { GetSessionsArgs, useGetSessionSummaryQuery } from "service/api-slice";
import AnalyticsDataCard from "components/cards/statistics/AnalyticsDataCard";

// ==============================|| USER CARD CHART ||============================== //

const TotalSessionsChart = (props: {
  height: number | null;
  sessionArgs: GetSessionsArgs;
}) => {
  const theme = useTheme();
  const { mode } = useConfig();

  const { data } = useGetSessionSummaryQuery(props.sessionArgs);

  const [count, setCount] = useState("");
  const [change, setChange] = useState<number | null>(null);

  const [series, setSeries] = useState([
    {
      name: "Sessions",
      data: [0],
    },
  ]);

  useEffect(() => {
    if (data) {
      setSeries([
        {
          name: "Sessions",
          data: data.data,
        },
      ]);
      setCount(`${data.totalSessions} Sessions`);
      setChange(data.totalChange);
    }
  }, [data]);

  // chart options
  const areaChartOptions = {
    chart: {
      id: "new-stack-chart",
      sparkline: {
        enabled: true,
      },
      height: 120,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      marker: {
        show: false,
      },
    },
  };

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState<ChartProps>(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      tooltip: {
        theme: mode === "dark" ? "dark" : "light",
      },
    }));
  }, [mode, primary, secondary, line, theme]);

  return (
    <AnalyticsDataCard
      title="charging-sessions-chart-label"
      count={count}
      percentage={props.sessionArgs.cpId ? null : change}
      color="secondary"
      isLoss={change ? change < 0 : null}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={props?.height?.toString()}
      />
    </AnalyticsDataCard>
  );
};

export default TotalSessionsChart;
