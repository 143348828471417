// project import
import dashboard from "./dashboard";
import management from "./management";
import other from "./other";

import { NavItemType } from "types/menu";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, management, other],
};

export default menuItems;
