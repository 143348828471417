// material-ui
import { Box, Chip, ChipProps, Stack, Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

interface Props {
  title: string;
  count: string;
  percentage?: number | null;
  isLoss?: boolean | null;
  color?: ChipProps["color"];
  children: any;
}

const AnalyticsDataCard = ({
  color = "primary",
  title,
  count,
  percentage,
  isLoss,
  children,
}: Props) => (
  <MainCard content={false}>
    <Box sx={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant="h6" color="textSecondary">
          <FormattedMessage id={title} />
        </Typography>
        <Stack direction="row" alignItems="center">
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
          {percentage && percentage !== 0 && (
            <Chip
              variant="combined"
              color={color}
              icon={
                <>
                  {isLoss === false && (
                    <RiseOutlined
                      style={{ fontSize: "0.75rem", color: "inherit" }}
                    />
                  )}
                  {isLoss === true && (
                    <FallOutlined
                      style={{ fontSize: "0.75rem", color: "inherit" }}
                    />
                  )}
                </>
              }
              label={`${percentage}%`}
              sx={{ ml: 1.25, pl: 1 }}
              size="small"
            />
          )}
        </Stack>
      </Stack>
    </Box>
    {children}
  </MainCard>
);

export default AnalyticsDataCard;
