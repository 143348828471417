// material-ui
// import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

import { Box } from "@mui/material";

// assets
// import { SearchOutlined } from '@ant-design/icons';
// import { useHotkeys } from 'react-hotkeys-hook';
// import { useRef, useState } from 'react';
// import CommandPalette from 'react-cmdk';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  // const ref1 = useRef<HTMLDivElement>(null);

  // const [open, setOpen] = useState<boolean>(true);
  // const [search, setSearch] = useState("");

  // useHotkeys('ctrl+k', () => {
  //   ref1?.current?.getElementsByTagName("input")[0].focus()
  //   setOpen((current) => { return !current });
  // });

  // useHotkeys('meta+k', () => {
  //   ref1?.current?.getElementsByTagName("input")[0].focus()
  // });

  return (
          // <CommandPalette
      //   onChangeSearch={setSearch}
      //   onChangeOpen={setOpen}
      //   search={search}
      //   isOpen={open}>

      //   <CommandPalette.Page id='page 1'>

      //   <CommandPalette.List heading='Group 1'>
      //     <CommandPalette.ListItem
      //       index={0}
      //       closeOnSelect={true}
      //       keywords={["hello", "star wars"]}
      //     />
      //   </CommandPalette.List>

      //   </CommandPalette.Page>

      //   <CommandPalette.Page id='page 2'>
      //   <CommandPalette.List heading='Group 2'>
      //     <CommandPalette.ListItem
      //       index={0}
      //       closeOnSelect={true}
      //       keywords={["bye", "future"]}
      //     />
      //   </CommandPalette.List>
      //   </CommandPalette.Page>
      // </CommandPalette>

    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      {/* <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <OutlinedInput
          size="small"
          ref={ref1}
          id="header-search"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: -0.5 }} >
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          placeholder="Ctrl + K"
        />
      </FormControl> */}
    </Box>
  );
};

export default Search;
