// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  ShopOutlined,
  ProfileOutlined,
  ThunderboltOutlined,
  AlertOutlined
} from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = {
  ShopOutlined,
  ThunderboltOutlined,
  ProfileOutlined,
  AlertOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const management: NavItemType = {
  id: "group-management",
  type: "group",
  title: <FormattedMessage id="management" />,
  children: [
    {
      id: "sites",
      title: <FormattedMessage id="sites" />,
      type: "item",
      url: "/sites",
      icon: icons.ShopOutlined,
      breadcrumbs: true,
    },
    {
      id: "charge-points",
      title: <FormattedMessage id="charge-points" />,
      type: "item",
      url: "/charge-points",
      icon: icons.ThunderboltOutlined,
      breadcrumbs: true,
      children: [
        {
          id: "charge-point-details",
          title: "Charge Point Details", //<FormattedMessage id="charge-points" />,
          breadcrumbs: true,
          url: "/charge-points/:id",
          type: "item",
        },
      ],
    },
    {
      id: "sessions",
      title: <FormattedMessage id="sessions" />,
      type: "item",
      url: "/sessions",
      icon: icons.ProfileOutlined,
      breadcrumbs: true,
    },
    {
      id: "power-mgmt",
      title: <FormattedMessage id="power-management" />,
      type: "item",
      url: "/power",
      icon: icons.AlertOutlined,
      breadcrumbs: true,
    },
  ],
};

export default management;
