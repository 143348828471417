
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { sec } from "utils/security";

export interface Session {
  id: string,
  charge_point_id: string;
  connector_id: number;
  start_time: string;
  meter_start: number;
  end_time: string;
  meter_end: number;
  end_reason: string;
}

export interface Envelope<T> {
  meta: Meta;
  data: T
}

export interface Meta {
  pagination: Pagination;
  status: number;
}

export interface Pagination {
  page_count: number;
  page: number;
  count: number;
}

export interface GetSessionsArgs {
  siteId: string;
  start: string;
  end: string;
  count: number;
  page: number;
  cpId?: string | null;
}

export interface GetOcppMessagesArg {
  cpId: string,
  count: number;
  page: number;
}

export interface OcppMessage {
  id: string;
  charge_point_id: string;
  origin: string;
  unique_id: string;
  created_at: string;
  action: string;
  message_type: number;
  payload: any;
}

interface Site {
  id: string;
  name: string;
}

export interface ChargePoint {
  id: string;
  approved: boolean;
  manufacturer: string;
  model: string;
  serial_number: string;
  firmware_version: string;
  created_at: string;
  status: ChargePointStatus | null;
}

interface ChargePointStatus {
  createdAt: string;
  status: string;
  info: string | null;
  connectorId: number;
  count?: number | null;
  page?: number | null;
}

interface EnergyUsed {
  totalEnergyUsed: number;
  totalChange: number | null;
  labels: Array<string>;
  data: Array<number>;
}

interface SessionSummary {
  totalSessions: number;
  totalSessionsPastPeriod: number;
  totalChange: number | null;
  data: Array<number>;
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers, api) => {
      const token = await sec.getAccessTokenSilently();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: builder => ({
    getRecentSessions: builder.query<Envelope<Session[]>, GetSessionsArgs>({
      query: (args) => {
        const path = args.cpId ? `/charge-points/${args.cpId}/sessions` : "/sessions/recent"
        return `${path}?start=${args.start}&end=${args.end}&group=${args.siteId}&count=${args.count ?? 50}&page=${args.page ?? 0}`;
      }
    }),

    getEnergyUsed: builder.query<EnergyUsed, GetSessionsArgs>({
      query: (args) => {
        const path = args.cpId ? `/charge-points/${args.cpId}/energy-used` : "/energy-used"
        return `${path}?start=${args.start}&end=${args.end}&group=${args.siteId}`;
      }
    }),

    getSessionSummary: builder.query<SessionSummary, GetSessionsArgs>({
      query: (args) => {
        const path = args.cpId ? `/charge-points/${args.cpId}/sessions/summary` : "/sessions/summary"
        return `${path}?start=${args.start}&end=${args.end}&period=hourly&group=${args.siteId}`;
      }
    }),

    getOcppMessages: builder.query<Envelope<OcppMessage[]>, GetOcppMessagesArg>({
      query: (args) => {
        return `/charge-points/${args.cpId}/ocpp-messages?page=${args.page}&count=${args.count}`;
      }
    }),

    getSites: builder.query<Site[], void>({
      query: (args) => {
        return "/sites"
      }
    }),

    getChargePointStatus: builder.query<number[], void>({
      query: () => {
        return "/charge-points"
      },
      transformResponse: (response: Envelope<ChargePoint[]>, meta, arg) => {
        const Available = 0, Charging = 1, SuspendedEV = 2, Pending = 3, Offline = 4;
        let series = [0, 0, 0, 0, 0];
        response.data.forEach((cp) => {
          if (cp.status?.status === "Available") {
            series[Available]++;
          } else if (cp.status?.status === "Charging") {
            series[Charging]++;
          } else if (cp.status?.status === "Offline") {
            series[Offline]++;
          } else if (cp.status?.status === "Pending") {
            series[Pending]++;
          } else if (cp.status?.status === "SuspendedEV") {
            series[SuspendedEV]++;
          }
        });
        return series;
      },
    }),

    getChargePoints: builder.query<Envelope<ChargePoint[]>, void>({
      query: () => {
        return "/charge-points"
      },
    }),
  }),
});

export const { useGetRecentSessionsQuery, useGetSitesQuery, useGetChargePointStatusQuery, useGetEnergyUsedQuery, useGetSessionSummaryQuery, useGetChargePointsQuery, useGetOcppMessagesQuery } = apiSlice;
