import {
  Box,
  Button,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ChargePointStatusChart from "components/charts/ChargePointStatus";
import EnergyUsedChart from "components/charts/EnergyUsed";
import TotalSessionsChart from "components/charts/TotalSessions";
import MainCard from "components/MainCard";
import { useEffect } from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { Link as RouterLink } from "react-router-dom";
import {
  GetSessionsArgs,
  useGetRecentSessionsQuery,
  useGetSitesQuery,
} from "../../service/api-slice";

import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import { startOfMonth, startOfYear } from "date-fns";
import { endOfMonth } from "date-fns/esm";

const dateRanges = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "week",
    label: "This Week",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "ytd",
    label: "Year to Date",
  },
];

const now = new Date();
const defaultStartTime = startOfDay(now);
const defaultEndTime = endOfDay(now);

const DefaultDashboard = () => {
  const intl = useIntl();

  const [dateRange, setDateRange] = useState("month");
  const [site, setSite] = useState("all");

  const [sessionArgs, setSessionArgs] = useState<GetSessionsArgs>({
    start: defaultStartTime.toISOString(),
    end: defaultEndTime.toISOString(),
    count: 25,
    page: 0,
    siteId: site,
  });

  const sites = useGetSitesQuery();
  const { data, isLoading, isError } = useGetRecentSessionsQuery(sessionArgs);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let start = defaultStartTime;
    let end = defaultEndTime;

    if (dateRange === "week") {
      start = startOfWeek(now);
      end = endOfWeek(now);
    } else if (dateRange === "month") {
      start = startOfMonth(now);
      end = endOfMonth(now);
    } else if (dateRange === "ytd") {
      start = startOfYear(now);
    }

    setSessionArgs({
      start: start.toISOString(),
      end: end.toISOString(),
      siteId: site,
      count: 25,
      page: 0,
    });
  }, [dateRange, site]);

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h4">
              <FormattedMessage id="overview" />
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              select
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <MenuItem key="all" value="all">
                All
              </MenuItem>
              {sites.data &&
                sites.data.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <span>&nbsp;&nbsp;</span>
            <TextField
              size="small"
              select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
            >
              {dateRanges.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>

      {/* row 1 */}
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <MainCard content={false}>
          <Box sx={{ p: 2.25 }}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  <FormattedMessage id="charge-point-status-chart-label" />
                </Typography>
              </Grid>
              <Grid item>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <Button color="secondary" size="small" component={RouterLink} to={"charge-points"}>
                    <FormattedMessage id="view-charge-points" />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <ChargePointStatusChart height={160} />
          <br />
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <EnergyUsedChart height={160} sessionArgs={sessionArgs} />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <TotalSessionsChart height={160} sessionArgs={sessionArgs} />
      </Grid>

      {/* row 2 */}
      <Grid item xs={12} md={12} lg={12}>
        <MainCard
          content={false}
          title={intl.formatMessage({ id: "recent_sessions" })}
        >
          {/* table */}
          <TableContainer>
            <Table sx={{ minWidth: 350 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Charge Point</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell align="right">Meter Start</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell align="right">Meter End</TableCell>
                  <TableCell align="right">Total Power Used</TableCell>
                  <TableCell sx={{ pr: 3 }}>Reason</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.data &&
                  data.data.map((session) => (
                    <TableRow hover key={session.id}>
                      <TableCell sx={{ pl: 3 }} component="th" scope="row">
                        {session.charge_point_id}
                      </TableCell>
                      <TableCell>{session.start_time}</TableCell>
                      <TableCell align="right">
                        {session.meter_start / 1000.0} kWh
                      </TableCell>
                      <TableCell>{session.end_time}</TableCell>
                      <TableCell align="right">
                        {session.meter_end / 1000.0} kWh
                      </TableCell>
                      <TableCell align="right">
                        {(session.meter_end - session.meter_start) / 1000.0} kWh
                      </TableCell>
                      <TableCell sx={{ pr: 3 }}>{session.end_reason}</TableCell>
                    </TableRow>
                  ))}
                {(isLoading || isError) &&
                  [0, 1, 2, 3, 4, 5].map((i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ pl: 3 }} component="th" scope="row">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="right">
                        <Skeleton />
                      </TableCell>
                      <TableCell sx={{ pr: 3 }} align="right">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      </Grid>

      <Grid
        item
        md={8}
        sx={{ display: { sm: "none", md: "block", lg: "none" } }}
      />
    </Grid>
  );
};

export default DefaultDashboard;
